import React, { useState, useEffect } from 'react';

const UnderConstruction = () => {
  const [text, setText] = useState('');
  const fullText = 'Website Under Construction';
  const typingSpeed = 200; // Adjust typing speed (milliseconds per character)
  const pauseTime = 1000; // Adjust pause time (milliseconds)
  const [isBackward, setIsBackward] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    let intervalId;
    let cursorInterval;

    const animateText = () => {
      if (isBackward) {
        if (currentIndex === 0) {
          setIsBackward(false);
          currentIndex = 0;
        } else {
          currentIndex--;
        }
      } else {
        if (currentIndex < fullText.length) {
          currentIndex++;
        } else {
          setIsBackward(true);
          currentIndex = fullText.length;
        }

        setText(fullText.slice(0, currentIndex));
      }
    };

    const toggleCursor = () => {
      setCursorVisible((prev) => !prev);
    };

    intervalId = setInterval(animateText, typingSpeed);
    cursorInterval = setInterval(toggleCursor, 500); // Adjust cursor blinking speed

    return () => {
      clearInterval(intervalId);
      clearInterval(cursorInterval);
    };
  }, [isBackward]);

  useEffect(() => {
    // Add a pause before restarting the typing animation
    const pauseId = setTimeout(() => {
      setIsBackward(false);
    }, pauseTime);

    return () => {
      clearTimeout(pauseId);
    };
  }, [isBackward]);

  return (
    <div className="under-construction">
      <div className="logo-container">
        <div className="logo-text">
          <span className="pink">D</span>
          <span className="blue">E</span>
          <span className="purple">N</span>
          <span className="pink">N</span>
          <span className="blue">I</span>
          <span className="purple">S</span>
        </div>
        <div className="logo-text">OMARI</div>
      </div>
      <h1>
        {text}
        {cursorVisible && <span className="cursor">|</span>}
      </h1>
    </div>
  );
};

export default UnderConstruction;
