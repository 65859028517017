// App.js

import React from 'react';
import UnderConstruction from './UnderConstruction'; // Import the UnderConstruction component
import './UnderConstruction.css'; // Import the CSS file

function App() {
  return (
    <div className="App">
      <UnderConstruction /> {/* Include the UnderConstruction component */}
    </div>
  );
}

export default App;
